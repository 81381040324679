<template>
    <div class="operation">
        <!--  -->
        <!-- <div class="head padd20 borbut20">

            <div class="zhong">
                <el-input v-model="input" placeholder="请输入用户手机号"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div>
            <div></div>
        </div> -->
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">通知列表</div>
                <el-button type="primary" id="Allocation" @click="addRen($event)" class="yinyinga">添加通知人员</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="头像">
                    <template slot-scope="scope">
                        <img :src="scope.row.user_msg.server_wechat.wechat_head" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="user_msg.server_wechat.wechat_name" label="昵称">
                    <template slot-scope="scope">
                        {{    Base64.decode(scope.row.user_msg.server_wechat.wechat_name)}}
                    </template>
                </el-table-column>
                <el-table-column prop="user_msg.server_wechat.wechat_sex" label="性别">
                    <template slot-scope="scope">
                        {{scope.row.user_msg.server_wechat.wechat_sex == 1 ? '男' : '女'}}
                    </template>
                </el-table-column>
                <el-table-column prop="user_msg.account" label="手机号">
                    <template slot-scope="scope">
                        <span>{{scope.row.user_msg.account ? scope.row.user_msg.account : '- -'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="user_msg.server_wechat.updated_at" label="地区">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user_msg.server_wechat.wechat_country"> {{scope.row.user_msg.server_wechat.wechat_country}} {{scope.row.user_msg.server_wechat.wechat_province}} {{scope.row.user_msg.server_wechat.wechat_city}} </span>
                        <span v-else>- -</span>
                    </template>
                </el-table-column>
                <el-table-column prop="user_msg.server_wechat.created_at" label="新增时间">
                </el-table-column>
                <el-table-column prop="user_msg.server_wechat.updated_at" label="活跃时间">
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user_msg.server_wechat.subscribe == 1" class="red"> <i class="iconfont icon-biaoqiankuozhan_guanzhu-344 red"></i> 关注中</span>
                        <span v-if="scope.row.user_msg.server_wechat.subscribe != 1" class=""><i class="iconfont icon-biaoqiankuozhan_guanzhu-344"></i> 取消关注</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger" class="shan">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <Allocation ref="Allocation" :id="operating_id" :msg="activeName" @zeng="zeng" @bianji="bianji" v-if="activeName == 'Allocation'"></Allocation>
        </div>
    </div>
</template>

<script>
import Allocation from "./Allocation.vue";
export default {
    data() {
        return {
            input: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示运营列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            page: "1",
            activeName: "",
        };
    },
    mounted() {
        this.getSysMessageUserList();
    },
    components: {
        Allocation,
    },
    methods: {
        //添加人员
        addRen(e) {
            // console.log(e.currentTarget.id);
            this.activeName = e.currentTarget.id;
        },
        bianji() {
            this.activeName = "";
            this.getSysMessageUserList();
        },
        zeng() {
            this.activeName = "";
        },
        // 删除
        onEnter(row) {
            var param = {
                user_id: row.user_msg.id,
            };
            this.$service.get(this.$api.delSysMessageUser, param, (res) => {
                if (res.code == "200") {
                    this.getSysMessageUserList();
                }
            });
        },
        // 获取通知列表
        getSysMessageUserList() {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getSysMessageUserList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    // console.log(this.tableData);
                    this.lvtotal = res.data.total;
                }
            });
        },
        //搜索
        sousuo() {
            var param = {
                page: 1,
                account: this.input,
            };
            // console.log(param);
            this.$service.get(this.$api.getSysMessageUserList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.operating_id = row.id;
            // console.log(e.srcElement.id);
            // console.log(index, row);
        },
        // 分页
        DialogCurrentChange(val) {
            // console.log(val);
            this.tableData = [];
            var param = {
                page: val,
            };
            this.$service.get(this.$api.getSysMessageUserList, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data);
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .shan {
        margin-left: 0px;
    }
}
</style>
<style lang="scss">
.operation {
    .el-input {
        width: 180px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
        background: #0075f7;
    }
    .el-dialog__title {
        color: #fff;
    }
}
</style>