<template>
    <div class="Configurente">
        <el-dialog title="添加通知人员" :visible.sync="confitionDialog" width="520px" @close="onCancel">
            <div class="dialog-inside Configurentedialog">
                <div class="sunbox distryof">
                    <div class="sunbox-text"><span class="text-xing">*</span>分配用户</div>
                    <div class="sunbox-content">
                        <el-select v-model="UserofInput" @change="UserChange" placeholder="请输入手机号码搜索用户" value-key="id" filterable remote :remote-method="generateData">
                            <el-option v-for="item in UserofList" :key="item.value" :label="Base64.decode(item.server_wechat.wechat_name) + (item.account ? item.account : '')" :value="item">
                                <span style="float: left">{{ Base64.decode(item.server_wechat.wechat_name)}}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.account ? item.account : '暂未绑定手机号' }}</span>
                            </el-option>
                        </el-select>
                        <div class="UserexList" v-if="UserofListHave.length">
                            <div class="Useryiyou" v-for="(item,key) in UserofListHave" :key="key">
                                <i class="el-icon-delete" @click="UserofDel(key)"></i>
                                {{ Base64.decode(item.server_wechat.wechat_name)}}
                                <span>（{{item.account ? item.account : '暂未绑定手机号'}}）</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        //穿梭框
        return {
            enterpriseData: {}, //企业数据
            confitionDialog: true,
            operValue: {}, //运营官选中
            operOptions: [],
            operPage: 1, //运营官下拉加载
            operListHave: {}, //运营官初始选中
            operating_name: "",

            UserofInput: "", //搜索用户input
            UserofList: [], //用户列表
            UserofListHave: [], //已分配的用户列表
            UserofPostList: [],

            GroupofInput: "", //群名搜索
            GroupofList: [], //用户列表
            GroupofListHave: [], //已分配的用户列表
            GroupofPostList: [],
        };
    },

    mounted() {
        this.generateData();
        // this.onUserofPostList()
        // this.UserChange()
    },
    methods: {
        //用户管理
        // 选中用户时
        UserChange() {
            var mess = true;
            this.UserofListHave.forEach((v, k) => {
                if (this.UserofInput.id == v.id) {
                    return (mess = false);
                }
            });
            if (mess) {
                this.UserofListHave.unshift(this.UserofInput);
                this.onUserofPostList();
            } else {
                this.$message({
                    message: "您已选择过这个用户",
                    type: "warning",
                });
            }
            this.UserofInput = {};
        },
        onUserofPostList() {
            this.UserofPostList = [];
            this.UserofListHave.forEach((v, k) => {
                var obj = { user_id: v.id, wx_id: v.server_wechat.id };
                this.UserofPostList.push(obj);
            });
        },
        //删除选过的用户
        UserofDel(key) {
            this.UserofListHave.splice(key, 1);
            this.onUserofPostList();
        },
        //调用用户列表
        generateData(account) {
            // this.baoliu = []
            var param = {
                account: account,
                corpor_account_id: this.enterpriseData.id,
            };
            this.$service.get(this.$api.getServeUserList, param, (res) => {
                if (res.code == "200") {
                    this.UserofList = res.data.data;
                    // console.log(this.UserofList);
                }
            });
        },
        //掉接口已有用户
        generateDatato(account) {
            // this.baoliu = []
            var param = {
                corpor_account_id: this.enterpriseData.id,
            };
            this.$service.get(this.$api.getServeUserList, param, (res) => {
                if (res.code == "200") {
                    this.UserofListHave = res.data.is_checked;
                    this.onUserofPostList();
                }
            });
        },

        //群管理
        // 选中群时
        GroupChange() {
            this.GroupofListHave = []; //已分配的用户列表*******选单个

            var mess = true;
            this.GroupofListHave.forEach((v, k) => {
                if (this.GroupofInput.id == v.id) {
                    return (mess = false);
                }
            });
            if (mess) {
                this.GroupofListHave.unshift(this.GroupofInput);
                this.onGroupofPostList();
            } else {
                this.$message({
                    message: "您已选择过这个用户",
                    type: "warning",
                });
            }
            this.GroupofInput = {};
        },
        onGroupofPostList() {
            this.GroupofPostList = [];
            this.GroupofListHave.forEach((v, k) => {
                var obj = { company_id: v.id };
                this.GroupofPostList.push(obj);
            });
            // console.log(this.GroupofPostList, this.GroupofListHave);
        },
        //删除选过的群
        GroupofDel(key) {
            this.GroupofListHave.splice(key, 1);
            this.onGroupofPostList();
        },
        //调用群列表
        GroupgenerateData(company_name) {
            // this.baoliu = []
            var param = {
                company_name: company_name,
                corpor_account_id: this.enterpriseData.id,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.GroupofList = res.data.data;
                    this.onGroupofPostList();
                }
            });
        },
        //掉接口已有群
        GroupgenerateDatato() {
            // this.baoliu = []
            var param = {
                corpor_account_id: this.enterpriseData.id,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.GroupofListHave = res.data.is_checked;
                    this.onGroupofPostList();
                }
            });
        },

        // // 弹窗接收数据
        // onReceive(data) {
        //     this.enterpriseData = data;
        //     this.generateData();
        //     this.generateDatato();
        //     this.GroupgenerateData();
        //     this.GroupgenerateDatato();
        //     this.ongetTopOperatingList();
        //     this.confitionDialog = true;
        // },
        //取消
        onCancel() {
            this.confitionDialog = false;
            this.$emit("zeng");
            (this.operValue = {}), //运营官选中
                (this.operOptions = []),
                (this.operPage = 1), //运营官下拉加载
                (this.operListHave = {}), //运营官初始选中
                (this.UserofInput = ""), //搜索用户input
                (this.UserofList = []), //用户列表
                (this.UserofListHave = []), //已分配的用户列表
                (this.UserofPostList = []),
                (this.GroupofInput = ""), //群名搜索
                (this.GroupofList = []), //用户列表
                (this.GroupofListHave = []), //已分配的用户列表
                (this.GroupofPostList = []);
        },
        //提交按钮
        onSubmit() {
            var prame = {
                wx_list: this.UserofPostList,
            };
            // createSysMessageUserList
            // console.log(this.GroupofPostList)
            this.$service.post(
                this.$api.createSysMessageUserList,
                prame,
                (res) => {
                    if (res.code == "200") {
                        this.$message({
                            message: "配置企业成功",
                            type: "success",
                        });
                        this.$emit("bianji");
                    }
                }
            );
        },
        //获取运营官列表
        ongetTopOperatingList(text) {
            this.operating_name = text;
            var prame = {
                page: this.operPage,
                corpor_account_id: this.enterpriseData.id,
                operating_name: this.operating_name,
            };
            this.$service.get(this.$api.getTopOperatingList, prame, (res) => {
                if (res.code == "200") {
                    if (
                        res.data.data.length == 10 ||
                        res.data.data.length > 0
                    ) {
                        var operOptions = this.operOptions;
                        res.data.data.forEach((v, k) => {
                            operOptions.push(v);
                        });
                        this.operOptions = operOptions;
                        this.operListHave = res.data.is_checked;
                    } else {
                        this.operPage--;
                    }
                }
            });
        },
        //运营官搜索
        onOperRemoteMethod($text) {
            this.operPage = 1;
            this.operOptions = [];
            this.ongetTopOperatingList($text);
        },
        //运营官搜索触发事件修改运营官选中数据
        onModifyOpenValue() {
            this.operListHave[0] = this.operValue;
            this.operValue = {};
        },
        load() {
            this.operPage++;
            if (!this.operating_name) {
                this.ongetTopOperatingList();
            }
        },
        //
    },
    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.Configurente {
    .Configurentedialog {
        .el-input {
            width: 300px;
            margin-bottom: 0;
        }
    }
}
</style>